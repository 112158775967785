import {
    SiDribbble,
    SiGithub,
    SiInstagram,
    SiLinkedin,
    SiX,
} from "@icons-pack/react-simple-icons";
import ExternalIconLink from "components/links/ExternalIconLink";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

export interface ITeamPerson {
    name: string;
    photo: ImageDataLike;
    section: "team" | "contributors";
    github?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    dribbble?: string;
    description: [string];
}

interface TeamPersonProps {
    person: ITeamPerson;
    /**
     * The component is styled differently based on its index in the list (even
     * elements to the left, odd to the right).
     */
    index: number;
}

const TeamPerson: React.FC<TeamPersonProps> = ({ person, index }) => {
    const { name, photo } = person;
    const image = getImage(photo);

    const leftAligned = index % 2 === 0;

    /* Notes:
       - When flex direction is 'row-reverse' the main-start and main-end points
         are permuted. So if we want to justify content to the end, we need to
         actually specify justify start.
     */

    return leftAligned ? (
        <div className="d-flex flex-wrap gap-4 justify-content-center justify-content-sm-start align-items-center">
            {image && <GatsbyImage image={image} alt={name} />}
            <PersonDescription {...{ person, leftAligned }} />
        </div>
    ) : (
        <div className="d-flex flex-wrap gap-4 justify-content-center flex-sm-row-reverse justify-content-sm-start align-items-right">
            {image && <GatsbyImage image={image} alt={name} />}
            <PersonDescription {...{ person, leftAligned }} />
        </div>
    );
};

interface PersonDescriptionProps {
    person: ITeamPerson;
    leftAligned: boolean;
}

const PersonDescription: React.FC<PersonDescriptionProps> = ({
    person,
    leftAligned,
}) => {
    const { name, section, description } = person;

    const smTextAlign = leftAligned ? "text-sm-start" : "text-sm-end";

    return (
        <PersonDescriptionDiv
            className={`d-flex flex-column gap-2 text-center ms-sm-2 ${smTextAlign}`}
        >
            <h4 className="my-0">{name}</h4>
            <div className="d-flex flex-column gap-2 my-1">
                {description.map((line, index) => (
                    <p key={index} className="my-0 text-muted">
                        {line}
                    </p>
                ))}
            </div>
            {section === "team" && (
                <PersonSocials {...{ person, leftAligned }} />
            )}
        </PersonDescriptionDiv>
    );
};

const PersonDescriptionDiv = styled.div`
    /* To ensure that if one person flex-wraps, all of them flex-wrap (even if
       one of them doesn't have long lines to naturally give it a reason to
       wrap). */
    min-width: 50%;
`;

const PersonSocials: React.FC<PersonDescriptionProps> = ({
    person,
    leftAligned,
}) => {
    const { github, twitter, linkedin, instagram, dribbble } = person;
    const iconWidth = 20;

    return (
        <div
            className={`d-flex gap-2 justify-content-center ${
                leftAligned
                    ? "justify-content-sm-start"
                    : "justify-content-sm-end"
            }`}
        >
            {github && (
                <ExternalIconLink
                    href={`https://github.com/${github}`}
                    title="GitHub"
                >
                    <SiGithub width={iconWidth} />
                </ExternalIconLink>
            )}

            {twitter && (
                <ExternalIconLink
                    href={`https://twitter.com/${twitter}`}
                    title="X (Formerly Twitter)"
                >
                    <SiX width={iconWidth} />
                </ExternalIconLink>
            )}

            {linkedin && (
                <ExternalIconLink
                    href={`https://linkedin.com/in/${linkedin}`}
                    title="LinkedIn"
                >
                    <SiLinkedin width={iconWidth} />
                </ExternalIconLink>
            )}

            {instagram && (
                <ExternalIconLink
                    href={`https://instagram.com/${instagram}`}
                    title="Instagram"
                >
                    <SiInstagram width={iconWidth} />
                </ExternalIconLink>
            )}

            {dribbble && (
                <ExternalIconLink
                    href={`https://dribbble.com/${dribbble}`}
                    title="Dribbble"
                >
                    <SiDribbble width={iconWidth} />
                </ExternalIconLink>
            )}
        </div>
    );
};

export default TeamPerson;

/**
 * Split the people array into team and contributors based on the value of the
 * section field.
 */
export const splitPeople = (people: ITeamPerson[]) => {
    return people.reduce(
        (split, person) => {
            split[person.section === "team" ? 0 : 1].push(person);
            return split;
        },
        [[] as ITeamPerson[], [] as ITeamPerson[]],
    );
};
