import BasicSection from "components/BasicSection";
import Layout from "components/Layout";
import TeamPerson, { ITeamPerson, splitPeople } from "components/TeamPerson";
import { graphql, Link, PageProps } from "gatsby";
import React, { HTMLAttributes, PropsWithChildren } from "react";

interface DataProps {
    allTeamJson: {
        nodes: ITeamPerson[];
    };
}

const About: React.FC<PageProps<DataProps>> = ({ path, data }) => {
    const people = data.allTeamJson.nodes;

    return (
        <Layout title="Ente - About" path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>About</BasicSection.Heading>
                </BasicSection.Header>

                <BasicSection.BodyElevated>
                    <div className="mx-4 my-md-4">
                        <Introduction />
                        <Team {...{ people }} />
                    </div>
                </BasicSection.BodyElevated>
            </BasicSection>
        </Layout>
    );
};

export default About;

// Explicitly set the image format to PNG, otherwise the plugin creates a
// degraded quality JPG (not needed by us, the original images are small)
export const query = graphql`
    query {
        allTeamJson {
            nodes {
                name
                photo {
                    childImageSharp {
                        gatsbyImageData(width: 180, formats: [PNG])
                    }
                }
                section
                github
                twitter
                linkedin
                instagram
                dribbble
                description
            }
        }
    }
`;

const Introduction: React.FC = () => {
    return (
        <section>
            <IntroductionSlide title="Prelude">
                <p>We collectively take over a trillion photos every year.</p>
                <p>These pictures speak a thousand words.</p>
            </IntroductionSlide>

            <IntroductionSlide title="Our story">
                <p>
                    <b>Ente</b> was born out of a need for a private storage for
                    personal memories.
                </p>
                <p>
                    There was nothing out there that ticked all the boxes, so we
                    started building one.
                </p>
            </IntroductionSlide>

            <IntroductionSlide title="The present">
                <p>
                    We have apps across platforms that securely encrypt and
                    backup all your data.
                </p>
                <p>
                    They are preserved at{" "}
                    <Link to="/blog/replication-v3/">multiple locations</Link>,
                    and only you can decrypt them.
                </p>
                <p>
                    Our source code and architecture have been{" "}
                    <Link to="/blog/cryptography-audit/">audited</Link>.
                </p>
            </IntroductionSlide>

            <IntroductionSlide title="The future">
                <p>
                    We will continue to craft the best photos app ever, together
                    with our <Link to="/community">community</Link>.
                </p>
            </IntroductionSlide>
        </section>
    );
};

interface IntroductionSlideProps {
    title: string;
    salDelay?: number;
}

const IntroductionSlide: React.FC<
    PropsWithChildren<IntroductionSlideProps>
> = ({ title, salDelay = 0, children }) => {
    return (
        <div
            className="mb-5"
            data-sal="slide-down"
            data-sal-duration="600"
            data-sal-delay={salDelay}
        >
            <h4>{title}</h4>
            {children}
        </div>
    );
};

interface TeamProps {
    people: ITeamPerson[];
}

const Team: React.FC<TeamProps> = ({ people }) => {
    const [team, contributors] = splitPeople(people);

    return (
        <section id="team" className="pt-3">
            <PeopleSection title="Team">
                {team.map((person, index) => (
                    <div key={index} data-sal="fade" data-sal-duration="1000">
                        <TeamPerson {...{ person, index }} />
                    </div>
                ))}
            </PeopleSection>

            <br />

            <PeopleSection title="Contributors">
                {contributors.map((person, index) => (
                    <div
                        key={person.name}
                        data-sal="fade"
                        data-sal-duration="1000"
                    >
                        <TeamPerson {...{ person, index }} />
                    </div>
                ))}
            </PeopleSection>

            <div data-sal="fade" data-sal-duration="2000">
                <OthersSection />
            </div>
        </section>
    );
};

const PeopleSection: React.FC<PropsWithChildren<{ title: string }>> = ({
    title,
    children,
}) => {
    return (
        <div>
            <PeopleHeading>{title}</PeopleHeading>
            <div className="d-flex flex-column gap-5">{children}</div>
        </div>
    );
};

const PeopleHeading: React.FC<
    PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>
> = ({ children }) => {
    return <h2 className="text-center p-4">{children}</h2>;
};

const OthersSection: React.FC = () => {
    return (
        <div>
            <h2 className="text-center pt-4">Others</h2>
            <div className="d-flex flex-column gap-5">
                <OthersDescription />
            </div>
        </div>
    );
};

const OthersDescription: React.FC = () => {
    return (
        <div className="text-center lh-sm">
            <p>
                There are many others, who have guided and helped this project
                come to life
            </p>
            <p>Friends, family, internet strangers, ...</p>
            <p>
                Thousands of open source contributors who built the wheels we
                roll on
            </p>
            <p>We are most grateful for everyone's kindness ❤️</p>
        </div>
    );
};
